body {
  height: 100vh;
  width: 100%;
  font-size: 16px;
}

.page {
  background: white;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  background-image: url('./clouds_mobile.png');
  background-repeat: no-repeat;
  background-position: 45% 8%;
  width: 100%;
  height: 100%;
  position: relative;
  text-align: center;
  padding: 36px;
}

.description {
  color: #797979;
  font-family: "Nunito";
  font-weight: 700;
  font-size: 24px;
  line-height: 1.5;
  text-align: left;
  position: relative;
}

.image-computer {
  width: 295px;
  height: auto;
  margin-bottom: 64px;
}

.image-viajy-mobile {
  width: 223px;
  height: auto;
  margin-bottom: 28px;
}

.image-viajy {
  display: none;
}

.image-rocket {
  margin-left: 40px;
  margin-bottom: 10px;
}

.message {
  width: 80%;
  max-width: 529px;
  margin-bottom: 40px;
}

.footer {
  background-image: url('./waves.png');
  background-size: contain;
  background-position: center center;
  background-repeat: repeat-x;
  width: 100%;
  height: 30px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media screen and (min-width: 64rem) {
  .page {
    height: 100vh;
  }

  .container {
    background-image: url('./clouds.png');
    background-size: cover;
    background-position: inherit;
    height: 60%;
    max-height: 620px;
    max-width: 1273px;
    text-align: left;
    padding: 0;
  }

  .description {
    position: absolute;
    left: 55%;
    top: 12.5%;
    padding: 0;
  }

  .image-computer {
    width: 480px;
    margin-left: 100px;
    margin-top: 7%;
    margin-bottom: 0;
  }

  .footer {
    height: 110px;
  }

  .image-viajy-mobile {
    display: none;
  }

  .image-viajy {
    display: inline-block;
    margin-bottom: 35px;
    margin-left: 75px;
  }

  .image-rocket {
    position: absolute;
    right: 3%;
    bottom: 90%;
    margin: 0;
  }
}
